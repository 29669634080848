<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="商品名称:">
                    <el-input class="s-input" v-model="queryParams.title" placeholder="请输入商品名称/编辑" clearable></el-input>
                </el-form-item>
                <el-form-item label="中台商品id:">
                    <el-input class="s-input" v-model="queryParams.source_id" placeholder="请输入中台商品id" clearable>
                    </el-input>
                </el-form-item>
                <el-form-item label="app商品id:">
                    <el-input class="s-input" v-model="queryParams.goods_id" placeholder="请输入app商品id" clearable>
                    </el-input>
                </el-form-item>
                <el-form-item label="商品编码:">
                    <el-input class="s-input" v-model="queryParams.goodssn" placeholder="请输入商品编码" clearable @change="changeQuery"></el-input>
                </el-form-item>
                <el-form-item label="状态:">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="上线" :value="2"></el-option>
                        <el-option label="下线" :value="1"></el-option>
                    </el-select>
                </el-form-item>

                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <!-- 表格内容 -->
            <el-table v-loading="loading" :data="pageInfo.list" element-loading-text="数据加载中" stripe border ref="multipleTable" style="margin-top: 30px;">
                <el-table-column prop="source_id" label="中台商品id" width="120" align="center"></el-table-column>
                <el-table-column prop="goods_id" label="app商品id" width="100" align="center"></el-table-column>
                <el-table-column label="封面图片" align="center" width="80" prop="image">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index)" style="height:23px;width:30px;" :class="`column${scope.$index}`" :src="scope.row.thumb" :preview-src-list="[scope.row.thumb]">
                            <div slot="error" class="image-slot">
                                <el-image fit="cover" :src="defaultImg"></el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="商品名称" width="300" align="center"></el-table-column>
                <el-table-column prop="specs" label="规格" width="200" align="center"></el-table-column>
                <el-table-column prop="weight" label="重量" width="70" align="center"></el-table-column>
                <el-table-column prop="productprice" label="售价" width="80" align="center"></el-table-column>
                <el-table-column prop="marketprice" label="市场价" width="80" align="center"></el-table-column>
                <el-table-column prop="costprice" label="成本价" width="80" align="center"></el-table-column>
                <el-table-column prop="stock" label="库存" width="80" align="center"></el-table-column>
                <el-table-column prop="goodssn" label="商品编码" width="150" align="center"></el-table-column>
                <el-table-column prop="productsn" label="商品条码" width="150" align="center"></el-table-column>
                <el-table-column prop="utime" label="更新时间" width="150" align="center"></el-table-column>
                <el-table-column label="状态" prop="state" align="center" width="100">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                        <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import { request } from '@/common/request';
import { deal } from '@/common/main';
export default {
    components: {
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            defaultImg: require('../../assets/img/zhan.jpeg'),
            header: {},
            menuList: ['商品', '中台商品'],
            loading: false,
            title: '加载中',
            checked: "true",
            addVisible: false,
            form: {
                state: 1
            },
            pageInfo: {
                list: [],
            },
            queryParams: {
                page: 1,
                pagesize: 10,
                platform: 'admin'
            },

            confirmContent: '',
            confirmVisible: false,
            queryParams: {
                name: '',
                page: 1,
                pagesize: 10,
                platform: 'admin'
            },
            beginDate: [],
        };
    },
    created() {
        this.loadData();
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
    },
    methods: {
        // 查看大图
        showBigImg(index) {
            this.$nextTick(function () {
                let shows = document.getElementsByClassName(`column${index}`)
                let show = shows[0].getElementsByClassName('el-image-viewer__wrapper')
                show[0].style['display'] = 'block'
                let dom = show[0].getElementsByClassName('el-image-viewer__mask')
                dom[0].addEventListener('click', function () {
                    show[0].style['display'] = 'none'
                })
            })
        },

        // 图片上传
        handleAvatarSuccess(res, file) {
            if (res.code == 1) {
                this.$set(this.form, 'thumb', res.data.uri);
            }
        },
        beforeAvatarUpload(file) {
            const _this = this
            return deal.imageFormat(file, _this)
        },
        cellstyle() {
            return "text-align: center";
        },
        // 状态
        state(row, state) {
            if (state == 2) {
                this.confirmContent = '确定是否上线?';
            } else {
                this.confirmContent = '确定是否下线?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state;
            this.form.ctime = deal.timestamp(this.form.ctime);
            this.form.etime = deal.timestamp(this.form.etime);
            this.confirmVisible = true;
        },
        loadData() {
            this.loading = true;
            // 获取中台商品列表
            request.get('/goods/source/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;
                    this.pageInfo.list.map(function (val) {

                        if (val.goods && val.goods[0]) {
                            val.goods = val.goods[0].list;
                        }
                        val.utime = deal.timestamp(val.utime, 'unix');
                        val.etime = deal.timestamp(val.etime, 'unix');
                        return val;
                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 分页
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val
            this.loadData()
        },
        refreshPageNum(val) {
            this.queryParams.page = val
            this.loadData()
        },
        changeBegin(r) {
            this.queryParams.begin_time = this.beginDate[0];
            this.queryParams.end_time = this.beginDate[1];
        },
    }
};
</script>

<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-left: 200px;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader {
    padding-bottom: 13px;
}

.el-icon-plus avatar-uploader-icon {
    margin-right: 50px;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 70px;
    height: 70px;
    line-height: 110px;
    text-align: center;
}

.avatar-uploader .avatar-uploader-icon {
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.avatar-uploader .avatar {
    width: 100px;
    height: 100px;
}

.avatar {
    width: 100px;
    height: 100px;
    display: block;
    font-size: 28px;
    line-height: 100px;
}
</style>